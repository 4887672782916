table.sortable {
  tr {
    th.sort-handle {
      width:1em;
      padding:0 5px;
    }
    td.sort-handle {
      cursor: grab;
    }
    td.sort-handle:active {
      cursor: grabbing;
    }
  }
}

div.sortable {
  div.item {
    div.sort-handle {
      cursor: grab;
      div.icon {
        width:32px !important;
        height:32px !important;
      }
    }
    div.sort-handle:active {
      cursor: grabbing;
    }
  }
}
