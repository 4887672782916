@import "fortee/scss/_init";

a:not(.btn):not(.badge) {
  color: inherit;

  &:hover, &:focus {
    //text-decoration: underline;
    color: $primary !important;
  }
}

label+.text-muted {
  a {
    color: $primary;
  }
}
