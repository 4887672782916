@for $i from 5 through 100 {
  .sH-#{$i}  { height: #{$i}px !important; }
  .sW-#{$i}  { width: #{$i}px !important; }
  .lH-#{$i}  { line-height: #{$i}px !important; }

  .smH-#{$i}  { min-height: #{$i}px !important; }
  .smW-#{$i}  { min-width: #{$i}px !important; }
  .sMH-#{$i}  { max-height: #{$i}px !important; }
  .sMW-#{$i}  { max-width: #{$i}px !important; }
}

@for $i from 100 through 1000 {
  @if $i % 10 == 0 {
    .sH-#{$i}  { height: #{$i}px !important; }
    .sW-#{$i}  { width: #{$i}px !important; }

    .smH-#{$i}  { min-height: #{$i}px !important; }
    .smW-#{$i}  { min-width: #{$i}px !important; }
    .sMH-#{$i}  { max-height: #{$i}px !important; }
    .sMW-#{$i}  { max-width: #{$i}px !important; }
  }
}

@for $i from 10 through 100 {
  @if $i % 10 == 0 {
    .sWp-#{$i}  { width: #{$i}\% !important; }
  }
}

@for $i from 5 through 50 {
  .fS-#{$i}  { font-size: #{$i}px !important; }
}

.sW-100p { width:100%; }
.sH-100p { height:100%; }
.fw-normal { font-weight: normal !important; }
.fw-bold { font-weight: bold !important; }

