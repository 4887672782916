@import "fortee/scss/_init";

.alert-note {
  --#{$prefix}alert-color: #{$gray-800};
  --#{$prefix}alert-bg: #{$gray-100};
  --#{$prefix}alert-border-color: #{$gray-300};
  --#{$prefix}alert-link-color: #{shade-color($gray-800, 20%)};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: var(--#{$prefix}alert-link-color);
  }

  ul {
    margin:0;
    padding-left:20px;
    li {

    }
  }
}
