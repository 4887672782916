@for $i from 0 through 10 {
  .pp-#{$i}  { padding: #{$i}px !important; }
  .pT-#{$i} { padding-top: #{$i}px !important; }
  .pR-#{$i} { padding-right: #{$i}px !important; }
  .pB-#{$i} { padding-bottom: #{$i}px !important; }
  .pL-#{$i} { padding-left: #{$i}px !important; }

  .pY-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .pX-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
}

@for $i from 10 through 35 {
  //@if $i % 5 == 0 {
    .pp-#{$i}  { padding: #{$i}px !important; }
    .pT-#{$i} { padding-top: #{$i}px !important; }
    .pR-#{$i} { padding-right: #{$i}px !important; }
    .pB-#{$i} { padding-bottom: #{$i}px !important; }
    .pL-#{$i} { padding-left: #{$i}px !important; }

    .pY-#{$i} {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }

    .pX-#{$i} {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
  //}
}

@for $i from 40 through 160 {
  @if $i % 10 == 0 {
    .pp-#{$i}  { padding: #{$i}px !important; }
    .pT-#{$i} { padding-top: #{$i}px !important; }
    .pR-#{$i} { padding-right: #{$i}px !important; }
    .pB-#{$i} { padding-bottom: #{$i}px !important; }
    .pL-#{$i} { padding-left: #{$i}px !important; }

    .pY-#{$i} {
      padding-top: #{$i}px !important;
      padding-bottom: #{$i}px !important;
    }

    .pX-#{$i} {
      padding-left: #{$i}px !important;
      padding-right: #{$i}px !important;
    }
  }
}
