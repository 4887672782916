@import "fortee/scss/_init";

span.shortcut-key {
  //border: 1px solid $gray;
  //color: $gray-dark;
  border: 1px solid $gray;
  color: $gray;
  margin-left: 5px;
  top: 4px;
  padding: 0 5px;
  border-radius: 4px;
  height:20px;
  text-align: center;
  display: inline-block;
  font-size: 9pt;

  //&:first-child {
  //  margin-right:5px;
  //}
  //&:last-child {
  //  margin-left:5px;
  //}
}

a.btn-circle-icon {
  border-radius: 50%;
  svg {
    margin:0;
  }
}

span.tab {
  border: 1px solid $gray;
  font-size: small;
  color: $gray;
  margin:0 5px;
  padding:2px 5px;
  border-radius: 4px;
}
