div.key-value {
  div.key {
    @extend .badge;
    @extend .badge-light;
    @extend .mB-10;
    width: 100%;
    padding:0;
  }
  div.value {
    padding-left:8px;
    a {
    }
  }
  div.help {
    @extend .text-gray;
    @extend .mT-5;
    @extend .fs-6;
    //padding-left:8px;
  }

  @extend .mB-20;
}

div.key-value-inline {
  @extend .d-flex;
  //@extend .flex-wrap;

  margin-right:20px;
  margin-bottom: 5px;

  div.key {
    @extend .badge;
    @extend .badge-light;
    @extend .mR-10;
  }
  div.value {
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
  }
  &.no-mb {
    margin-bottom: 5px;
  }
}

div.actions, td.actions {
  @extend .nowrap;
  .btn {
    margin-right:5px;
    margin-bottom:5px;

    &:last-child {
      margin-right:0;
    };
  }
}
