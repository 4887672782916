@import "fortee/scss/_init";
// Overriding /components/forms/_required.scss

.required {
  &:after {
    content: none;
  }

  label.form-label {
    div {
      display:inline;
      div.conference-text {
        display:inline;
        p {
          display:inline;
        }
      }
    }

    &:after {
      content: "*";
      position: relative;
      font-size: inherit;
      color: var(--#{$prefix}danger);
      padding-left: 0.25rem;
      font-weight: $font-weight-bold;
    }
  }
}
