@import "_add-border";
@import "_alert";
@import "_font-awesome";
@import "_form";
@import "_link";
@import "_required";
@import "_sidebar";
@import "_toastr";

.app-header {
  z-index:240 !important;
}

.app-drawer {
  z-index:260 !important;
}

#kt_app_content_container {
  > .row {
    margin: 0 !important;
  }
}

p {
  margin-bottom:5px;
}

[data-kt-app-layout="light-sidebar"] {
  .app-header {
    background-color: var(--bs-app-bg-color);
  }
}

[data-kt-app-layout="dark-sidebar"] {
  .app-header {
    background-color: var(--bs-app-bg-color);
  }
}

.card-toolbar {
  .btn {
    margin-right:5px;

    &:last-child {
      margin-right:0;
    }
  }
}

i {
  color: inherit !important;
}

