div.form-check {
  margin-top: 15px;
  margin-bottom: 15px;

  small {
    display:block;
  }

  label {
    cursor: pointer;
  }
}

label.form-check-label {
  color: var(--#{$prefix}body-color);
}
