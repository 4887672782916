.nowrap {
  white-space: nowrap;
}

.clickable {
  @extend .user-select-none;
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

