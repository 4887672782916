.badge {
  .fa, .fas, .fab, .fal, .fa-regular, .fa-solid, .fa-brands, .fa-light, .fa-thin {
    color: inherit;
    //margin-right:10px;
  }
}

i {
  &.bi,
  &[class^="fonticon-"],
  &[class*=" fonticon-"],
  &[class^="fa-"],
  &[class*=" fa-"],
  &[class^="la-"],
  &[class*=" la-"] {
    line-height: inherit ;
    font-size: inherit ;
    color: inherit ;
  }
}
