@import "fortee/scss/_init";
div.image-editor {
  div.dnd-zone {
    display:flex;
    align-items:center;
    justify-content:center;
    border: 1px solid $gray-500;

    cursor:pointer;
    overflow: hidden;

    background-color: white;

    .image-zone {
      .no-image {
        color: $gray-800;
      }
      .image {
        width:100%;
        object-fit: contain;
      }
    }
    .hidden {
      display:none;
    }
  }

  .dragover {
    border: 2px solid $gray-800;
  }

  .button-zone {
    li { cursor:pointer; }
  }

  .sling {
    position:absolute;
    left:-3000px;
  }
}
