.click2copy {
  cursor:pointer;

  //&:hover {
  //  color:$btn-primary-bg;
  //}

  svg {
    margin-left:5px;
  }
}
