//
// Variables
//

// Reboot
$app-bg-color: $gray-100;
$app-bg-color-dark:#151521;
$app-blank-body-bg-color: $body-bg;

// General
$font-family-sans-serif:      -apple-system, "BlinkMacSystemFont", "Helvetica Neue", Helvetica, "Arial", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-serif:           "Times New Roman", Times, "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif !default;
$font-family-base:            $font-family-sans-serif !default;

$app-general-root-font-size-desktop: 15px;
$app-general-root-font-size-tablet: 12px;
$app-general-root-font-size-mobile: 12px;

// Header base
$app-header-base-height: 70px;
$app-header-base-height-mobile: 60px;
$app-header-base-bg-color: $body-bg;
$app-header-base-bg-color-dark:#1e1e2d;
$app-header-base-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-header-base-box-shadow-dark: none;
$app-header-base-menu-link-bg-color-active: $menu-link-bg-color-active;
$app-header-base-menu-link-bg-color-active-dark:#2A2A3C;

// Header light
$app-header-light-separator-color: #E4E6EF;
$app-header-light-separator-color-dark: $border-color;

// Header dark
$app-header-dark-bg-color: #1e1e2d;
$app-header-dark-separator-color: #282a3d;
$app-header-dark-scrollbar-color: #3b3b64;
$app-header-dark-scrollbar-color-hover: lighten($app-header-dark-scrollbar-color, 3%);

// Sidebar base
$app-sidebar-base-width: 265px;
$app-sidebar-base-width-mobile: 250px;
$app-sidebar-base-toggle-btn-bg-color: $body-bg;
$app-sidebar-base-toggle-btn-bg-color-dark: $app-header-base-menu-link-bg-color-active-dark;

// Sidebar minimize
$app-sidebar-minimize-width: 75px;

// Sidebar light
$app-sidebar-light-bg-color: $body-bg;
$app-sidebar-light-bg-color-dark:#1e1e2d;
$app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82,63,105,.05);
$app-sidebar-light-box-shadow-dark: none;
$app-sidebar-light-separator-color: $app-header-light-separator-color;
$app-sidebar-light-scrollbar-color: $gray-200;
$app-sidebar-light-scrollbar-color-dark: $gray-200-dark;
$app-sidebar-light-scrollbar-color-hover: $gray-200;
$app-sidebar-light-scrollbar-color-hover-dark: $gray-200-dark;
$app-sidebar-light-menu-heading-color: $gray-500;
$app-sidebar-light-menu-heading-color-dark: $gray-500-dark;
$app-sidebar-light-menu-link-bg-color-active: #F4F6FA;
$app-sidebar-light-menu-link-bg-color-active-dark:#2A2A3C;
$app-sidebar-light-header-menu-link-bg-color-active: #EAEEF2;
$app-sidebar-light-header-menu-link-bg-color-active-dark: $gray-100-dark;

// Sidebar dark
$app-sidebar-dark-bg-color: #202B46; //#1e1e2d;
$app-sidebar-dark-bg-color-dark: #1e1e2d;
$app-sidebar-dark-separator-color: #33446C;
$app-sidebar-dark-separator-color-dark: $gray-200-dark;
$app-sidebar-dark-scrollbar-color: #3b3b64;
$app-sidebar-dark-scrollbar-color-hover: lighten($app-sidebar-dark-scrollbar-color, 2%);
$app-sidebar-dark-menu-heading-color: #646477;
$app-sidebar-dark-menu-heading-color-dark: #646477;
$app-sidebar-dark-menu-link-bg-color-active:#3E97FF;
$app-sidebar-dark-toggle-btn-bg-color: #323C55;
$app-sidebar-dark-toggle-btn-bg-color-dark: $gray-200-dark;


// Toolbar base
$app-toolbar-base-height: 55px;
$app-toolbar-base-bg-color: $body-bg;
$app-toolbar-base-bg-color-dark: darken(#1e1e2d, 2%);
$app-toolbar-base-box-shadow: 0px 10px 30px 0px rgba(82,63,105,0.05);
$app-toolbar-base-box-shadow-dark: none;
$app-toolbar-base-border-top: 1px solid $border-color;
$app-toolbar-base-border-top-dark: 0;

// Footer
$app-footer-height: 60px;
$app-footer-height-mobile: auto;
$app-footer-bg-color: $body-bg;
$app-footer-bg-color-dark:#1e1e2d;

// Scrolltop
$scrolltop-bottom: 43px;
$scrolltop-bottom-mobile: 23px;
$scrolltop-end: 7px;
$scrolltop-end-mobile: 5px;

// Layout builder toggle(used for Demo purposes only)
$app-layout-builder-toggle-bg-color:#323C55;
$app-layout-builder-toggle-bg-color-dark:#F4F6FA;
$app-layout-builder-toggle-end: 50px;
$app-layout-builder-toggle-end-mobile: 40px;
$app-layout-builder-toggle-bottom: 40px;
$app-layout-builder-toggle-bottom-mobile: 20px;




