.conference-text {
  ul {
    margin:5px 0;
    padding:0 0 0 20px;
  }
  &.editable {
    padding:0;
    margin:0;
    border:1px solid transparent;

    .edit-button {
      //float: right;
      cursor: pointer;
    }

    &:hover {
      border:1px dashed red;
    }

    p {
      &:last-child {
        margin-bottom:0;
      }
    }
  }
}
//label {
//  .conference-text.editable {
//    white-space: nowrap;
//    span.edit-button {
//      float: none;
//      cursor: pointer;
//    }
//  }
//}
