@import "fortee/scss/_init";

div.entity-list {
  > div.entity {
    &:first-child {
      border-top: $border-width solid $gray-300;
    }
    border-bottom: $border-width solid $gray-300;
    padding:20px 0;
  }

}
div.entity {
  width:100%;
}
div.entity-list.slim {
  > div.entity {
    padding:10px 0;
  }
}
