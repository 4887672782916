//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/functions";
@import "../vendor/keen_v3.0.3/fortee/src/sass/style.scss";

@each $item, $color in $colors {
  .c-#{$item},     .cH-#{$item}:hover      { color: $color !important; }
  .bgc-#{$item},   .bgcH-#{$item}:hover    { background-color: $color !important; }
  .bdc-#{$item},   .bdcH-#{$item}:hover    { border-color: $color !important; }
  .fill-#{$item},  .fillH-#{$item}:hover   { fill: $color !important; }
  .str-#{$item},   .strH-#{$item}:hover    { stroke: $color !important; }
}

@each $item, $color in $theme_colors {
  .c-#{$item},     .cH-#{$item}:hover      { color: $color !important; }
  .bgc-#{$item},   .bgcH-#{$item}:hover    { background-color: $color !important; }
  .bdc-#{$item},   .bdcH-#{$item}:hover    { border-color: $color !important; }
  .fill-#{$item},  .fillH-#{$item}:hover   { fill: $color !important; }
  .str-#{$item},   .strH-#{$item}:hover    { stroke: $color !important; }
}

$text-color:    $gray-900 !default;

$gray-lighter:  $gray-200;
$gray-light:    $gray-300;
$gray:          $gray-500;
$gray-dark:     $gray-700;
$gray-darker:   $gray-800;

.color-body         { color: var(--bs-body-color); }
.color-bg           { color: var(--bs-body-bg); }
.color-blue         { color: $blue !important; }
.color-indigo       { color: $indigo !important; }
.color-purple       { color: $purple !important; }
.color-pink         { color: $pink !important; }
.color-red          { color: $red !important; }
.color-orange       { color: $orange !important; }
.color-yellow       { color: $yellow !important; }
.color-green        { color: $green !important; }
.color-teal         { color: $teal !important; }
.color-cyan         { color: $cyan !important; }

.bg-color-blue      { background-color: $blue !important; }
.bg-color-indigo    { background-color: $indigo !important; }
.bg-color-purple    { background-color: $purple !important; }
.bg-color-pink      { background-color: $pink !important; }
.bg-color-red       { background-color: $red !important; }
.bg-color-orange    { background-color: $orange !important; }
.bg-color-yellow    { background-color: $yellow !important; }
.bg-color-green     { background-color: $green !important; }
.bg-color-teal      { background-color: $teal !important; }
.bg-color-cyan      { background-color: $cyan !important; }

.text-gray-lighter  { color: $gray-lighter; }
.text-gray-light    { color: $gray-light; }
.text-gray          { color: $gray; }
.text-gray-dark     { color: $gray-dark; }
.text-gray-darker   { color: $gray-darker; }
.text-primary       { color: $primary; }
.text-secondary     { color: $secondary; }
.text-success       { color: $success; }
.text-danger        { color: $danger; }
.text-warning       { color: desaturate($warning, 20%); }
.text-info          { color: desaturate($info, 20%); }

$text-gray-lighter: $gray-lighter;
$text-gray-light:   $gray-light;
$text-gray:         $gray;
$text-gray-dark:    $gray-dark;
$text-gray-darker:  $gray-darker;
$text-primary:      $primary;
$text-secondary:    $secondary;
$text-success:      $success;
$text-danger:       $danger;
$text-warning:      $warning;
$text-info:         $info;
