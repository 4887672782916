div.card {
  div.card-header.thumbnail {
    padding: 0;
    border: none;
    overflow: hidden;
    img.thumbnail {
      width: 100%;
      object-fit: contain;
      overflow: hidden;
    }
  }
}
