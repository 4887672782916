table.diff-html {
  width: 100%;

  tbody {
    tr {
      td {
        padding: 0 5px;
        word-break: break-all;
        vertical-align: top;
        &:after {
          content: "\00a0";
        }
      }
    }

    &.change-rep {
      tr {
        td.old {
          width:50%;
          background-color:#e6ffed;
          del, ins {
            text-decoration: none;
            background-color:#acf2bd;
          }
        }
        td.new {
          width:50%;
          background-color:#ffeef0;
          del, ins {
            text-decoration: none;
            border-color:#fdaeb7;
          }
        }
      }
    }
  }
}
