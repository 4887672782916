.file-uploader {
  .dnd-zone {
    border:1px dotted #ddd;
    padding:15px;
    color:#888888;

    margin:10px 0 20px 0;
    background-color:#f5f5f5;

    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;

    .form-group {
      display:none;
    }

    ul.queue {
      list-style-type: none;
      margin:10px 0 0 0;
      padding:0;

      li {
        margin-bottom:10px;
      }
    }

    label {
    }
  }

  .dragover {
    background-color:#fddfe2 !important;
  }
}
