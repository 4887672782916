div.fav {
  @extend .d-flex;

  margin-right:20px;
  width:50px;
  cursor: pointer;

  div.button {
    @extend .d-flex;

    div.on {
      color: #e0245e;
      display:none;
    }
    div.off {
      color: #657786;
    }
  }

  div.text {
    margin-left: 5px;
    color: #657786;
    content: "";
    margin-top: 1px;
  }

  div.count {
    margin-left: 8px;
    color: #657786;
    margin-top: 1px;
  }

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  &:hover {
    div.button {
      div.off {
        color: #e0245e !important;
      }
    }
    div.text {
      color: #e0245e !important;
    }
  }
}
div.fav.active {
  div.button {
    div.on {
      display:block;
    }
    div.off {
      display:none;
    }
  }
  div.text {
    display:none;
  }
}
