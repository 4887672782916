@import "fortee/scss/_init";
@import "bootstrap/scss/mixins/utilities";

div.md {
  h1, h2, h3, h4, h5 {
    margin-top:40px;
    &+p {
      margin-top:30px;
    }
  }
  h1 {
    font-size: $h3-font-size;
  }
  h2 {
    font-size: $h4-font-size;
  }
  h3 {
    font-size: $h5-font-size;
  }
  h4 {
    font-size: $h6-font-size;
  }
  h5 {
    font-size: $h6-font-size;
  }
  h6 {
    font-size: $h6-font-size;
  }

  h1+h1, h1+h2, h1+h3, h1+h4, h1+h5, h1+h6,
  h2+h1, h2+h2, h2+h3, h2+h4, h2+h5, h2+h6,
  h3+h1, h3+h2, h3+h3, h3+h4, h3+h5, h3+h6,
  h4+h1, h4+h2, h4+h3, h4+h4, h4+h5, h4+h6,
  h5+h1, h5+h2, h5+h3, h5+h4, h5+h5, h5+h6,
  h6+h1, h6+h2, h6+h3, h6+h4, h6+h5, h6+h6,
  {
    margin-top:0;
  }

  a {
    color: $primary !important;
  }

  pre {
    margin: 20px 0;
    code {
      margin:0;
      padding:0;
    }
  }
  ul {
    padding-left:20px;
  }
  p {
    margin-bottom:20px;

    &:last-child {
      margin-bottom:0;
    }
  }
}
