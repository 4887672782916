@import "../utils/colors";

div.image-uploader {
  div.dnd-zone {
    //display:flex;
    //align-items:center;
    //justify-content:center;

    cursor:pointer;
    overflow: hidden;

    background-color: white;

    .image-zone {
      .image {
        object-fit: cover;
      }
    }
  }

  .dragover {
    border: 2px solid $gray;
  }

  .sling {
    position:absolute;
    left:-3000px;
  }
}
